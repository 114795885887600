// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );
// $light: #f51313;
// $dark: #1ac50e;
// $primary: #030608;
// $danger: #ff4136;
/* import bootstrap to set changes */
// light : https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=D7CCC8&secondary.color=FFCCBC
// dark : 
@import "~bootstrap/scss/bootstrap";


$particlesLightBackground: #e7e7e5;
$particlesLightBackgroundOpacity: #e7e7e5cf;
$particlesLightColor: #cb9b8c;
$canvas3dLightBackground: #bbb4b1;
$canvas3dLightPointsHue: 0.2;
$particlesDarkBackground: #314047;
$particlesDarkBackgroundOpacity: #314047cf;
$particlesDarkColor: #ff844c;
$canvas3dDarkBackground: #455a64;
$canvas3dDarkPointsHue: 0.5;
:export {
    particlesLightBackground: $particlesLightBackground;
    particlesDarkBackground: $particlesDarkBackground;
    particlesLightColor: $particlesLightColor;
    particlesDarkColor: $particlesDarkColor;
    canvas3dLightBackground: $canvas3dLightBackground;
    canvas3dDarkBackground: $canvas3dDarkBackground;
}



.light-content {

    /* redefine theme colors for dark theme */
    $primary: #d7ccc8;
    $primary-dark: #a69b97;
    $primary-light: #d3d3d3;
    $secondary: #ffccbc;
    $secondary-dark: #cb9b8c;
    $secondary-light: #ffffee;
    $text-color: #000000;
    $theme-colors: ("primary": $primary,
        "secondary": $secondary,
        "success": $primary-light,
        "warning": $secondary-dark,
        "danger": $secondary-dark,
        "info": $secondary-light,
        "dark": $primary-dark,
        "light": $light,
    );

    /* redefine theme color variables */
    @each $color,
    $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    /* redefine theme color rgb vars (used for bg- colors) */
    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

    @each $color,
    $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    $body-color: #000000;
    $body-bg: $particlesLightBackground;

    --#{$variable-prefix}body-color: #{$body-color};
    --#{$variable-prefix}body-bg: #{$body-bg};
    @import "~bootstrap/scss/bootstrap";

    .card {
        border : 0px;
    }
    .card .card-body {
        background-color: $primary-light;
    }

    .list-group-item {
        background-color: $primary;
        border-left: 5px solid $primary;
        color: $text-color
    }

    .list-group-item.active {
        background-color: $primary;
        color: $text-color;
        border-left: 5px solid $secondary;
        border-top: 0px;
        border-bottom: 0px;
    }

    .list-group-item:hover {
        background-color: $primary;
        color: $text-color;
        border-left: 5px solid $secondary;
        border-top: 0px;
        border-bottom: 0px;
    }

    .customNavBar {
        color: $text-color;
        background-color: $primary;
    }

    .navbar-nav .nav-item button {
        background-color: $primary;
        border-left: 5px solid $primary
    }

    .navbar-nav .nav-item button:hover {
        background-color: $primary;
        border-left: 5px solid $secondary
    }

    .navbar-nav .nav-link {
        color: $text-color;
    }

    .navbar-nav .nav-item .active {
        background-color: $primary;
        color: $text-color;
        border-left: 5px solid $secondary
    }

    .navbar-brand {
        color: $text-color;
    }
    .infos-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    .canvas-container {
        border: 2px solid $secondary;
        margin: 0 !important;
        padding: 0 !important;
    }
    .canvas3d-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    #darkmodeswitch {
        background-color: $secondary;
        border-color: $secondary;
    }
    
    #darkmodeswitch:checked {
        border-color: $secondary;
    }
    .opacity-container {
        background-color: $particlesLightBackgroundOpacity;
    }
}


.dark-content {

    /* redefine theme colors for dark theme */
    $primary: #263238;
    $primary-dark: #000a12;
    $primary-light: #4f5b62;
    $secondary: #f4511e;
    $secondary-dark: #902b20;
    $secondary-light: #ff844c;
    $text-color: #ffffff;
    $theme-colors: ("primary": $primary,
        "secondary": $secondary,
        "success": $primary-light,
        "warning": $secondary-dark,
        "danger": $secondary-dark,
        "info": $secondary-light,
        "dark": $primary-dark,
        "light": $light,
    );

    /* redefine theme color variables */
    @each $color,
    $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    /* redefine theme color rgb vars (used for bg- colors) */
    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

    @each $color,
    $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    $body-color: $text-color;
    $body-bg: $particlesDarkBackground;

    --#{$variable-prefix}body-color: #{$body-color};
    --#{$variable-prefix}body-bg: #{$body-bg};
    @import "~bootstrap/scss/bootstrap";

    label.btn:active{
        background-color: $primary-dark
    }
    .card {
        border : 0px;
        background-color: transparent!important;
    }


    .list-group-item {
        background-color: $primary;
        border-left: 5px solid $primary;
        color: $text-color
    }

    .list-group-item.active {
        background-color: $primary;
        color: $text-color;
        border-left: 5px solid $secondary;
        border-top: 0px;
        border-bottom: 0px;
    }

    .list-group-item:hover {
        background-color: $primary;
        color: $text-color;
        border-left: 5px solid $secondary;
        border-top: 0px;
        border-bottom: 0px;
    }

    .customNavBar {
        color: $text-color;
        background-color: $primary;
    }

    .navbar-nav .nav-item button {
        background-color: $primary;
        border-left: 5px solid $primary
    }

    .navbar-nav .nav-item button:hover {
        background-color: $primary;
        border-left: 5px solid $secondary
    }

    .navbar-nav .nav-link {
        color: $text-color;
    }

    .navbar-nav .nav-item .active {
        background-color: $primary;
        color: $text-color !important;
        border-left: 5px solid $secondary
    }

    .navbar-brand {
        color: $text-color;
    }

    .canvas-container {
        border: 2px solid $secondary;
        margin: 0 !important;
        padding: 0 !important;
    }
    .infos-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    .canvas3d-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    #darkmodeswitch {
        background-color: $secondary;
        border-color: $secondary;
    }
    
    #darkmodeswitch:checked {
        border-color: $secondary;
    }
    .opacity-container {
        background-color: $particlesDarkBackgroundOpacity;
    }
}

